<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h1 class="mb-15">Sign In</h1>
				<h5 class="font-regular text-muted">Entrez votre email et votre mot de passe pour vous connecter</h5>

				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="Email" :colon="false">
						<a-input 
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: ' Entrez votre adresse email' }] },
						]" placeholder="Email" />
					</a-form-item>
					<a-form-item class="mb-5" label="Password" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Entreznter un mot de passe' }] },
						]" type="password" placeholder="Password" />
					</a-form-item>
					<a-form-item class="mb-10">
    					<a-switch v-model="rememberMe" /> Remember Me
					</a-form-item>
					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button">
							Se connecter
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->

				<p class="font-semibold text-muted">Don't have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign Up</router-link></p>
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/img-signin.jpg" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>
const axios = require('axios')
const connect = require('../config_developpemenrt')
const instance = axios.create({
  baseURL:`${connect.baseUri}/connecting-user/`
})

	export default ({
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				rememberMe: true,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						console.log('Received values of form: ', values)
            instance.post(`${connect.baseUri}/connecting-user/`,{email:values.email, password:values.password})
                .then((response)=> {
                  console.log(response.data)
                  let user_privileges = response.data.user_privileges
                  localStorage.setItem('user', JSON.stringify(response.data.user))
                  localStorage.setItem('jwt', response.data.token)
                  if (localStorage.getItem('jwt') != null) {
                    this.$emit('loggedIn')
                    console.log('loggedIn!!!!')
                    if (this.$route.params.nextUrl != null) {
                      this.$router.push(this.$route.params.nextUrl)
                      console.log('to next url')
                    }
                  }
                  else {
                    if (response.data.user.user_privileges === 'adm') {
                      console.log('is admin!!!')
                      this.$router.push('Tables')
                    } else {
                      this.$router.push('dashboard')
                    }
                  }
                }).catch(err=>console.log(err))
					}
				});
			},
		},
	})

</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>